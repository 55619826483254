/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { CardMedia, colors } from '@mui/material';


import Container from 'components/Container';
// import pdf1 from 'assests/Pdf/CCA/Associationactivity2021-22.pdf';
// import pdf2 from 'assests/Pdf/CCA/INTERSCHOOLPPT2021-22.pdf';

// import p1 from 'assests/Photos/CoCurricular/2024/School_camps_2024_25.jpg';
// import p2 from 'assests/Photos/CoCurricular/2024/inter_school_competation.jpg';
// import p3 from 'assests/Photos/CoCurricular/2024/Clubs.jpg';
// import p4 from 'assests/Photos/CoCurricular/2024/association.jpg';




const Features2024 = () => {

const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/CoCurricular/2024/School_camps_2024_25.webp`;
  const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/CoCurricular/2024/inter_school_competation.webp`;
  const p3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/CoCurricular/2024/Clubs.webp`;
  const p4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/CoCurricular/2024/association.webp`;
  
  const mock = [
    {
      media: p1,
      color: colors.lightBlue[500],
      title: '1. School camps – Excursions/Field Trips',
      subtitle: '',
      href: '/class-6-field-trip-2024',
    },
    {
      media: p2,
      color: colors.lightBlue[500],
      title: '2. Inter-school Competition - Goonj 2024',
      subtitle: '',
      href: '/goonj-2024',
    },
    {
      media: p3,
      color: colors.lightBlue[500],
      title: '3. Clubs',
      subtitle: '',
      href: '/clubss',
    },
    {
      media: p4,
      color: colors.lightBlue[500],
      title: '4. Associations',
      subtitle: '',
      href: '/association',
    },
    // {
    //   media: p3,
    //   color: colors.lightBlue[500],
    //   title: '3. CCA and Club activities',
    //   subtitle: '',
    //   href: '/clubs',
    // },
   
  //   {
  //     color: colors.lightBlue[500],
  //     title: '3. CCA and Club activities',
  //     subtitle: '',
  //     href: '/clubs',
  //   },
  
  ];

  return (

    <Box >

      <Container>
        <Grid container spacing={8}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={8} md={4} key={i}>
              <Box
                
                bgcolor={'alternate.main'}>
                <Box component={Card} padding={4} width={1} height={1}  bgcolor={'alternate.main'}>
                  <Box display={'flex'} flexDirection={'column'}>
                  <CardMedia
                  image={item.media}
                  component = 'img'
                  sx={{
                    height: 240,
                  }}
                />
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        href ={item.href}
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Learn More
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

  );
};

export default Features2024;
