import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';

import {
  Features,
  Advantages,
} from './components';
import { Link, Typography } from '@mui/material';
import CCA2023 from './CCA2023';
import Features2023 from './Features2023';
import CCA2024 from './CCA2024';
import Features2024 from './Features2024';


const CCA = () => {
  const theme = useTheme();
  return (
    <Main>

        <Container  maxWidth={0.9} marginTop={0.6} paddingTop={'0 !important'}><br/>
        <CCA2024/>
        <Features2024/>
        <CCA2023/>
        <Features2023/>
        <Advantages />
          <Features />
        </Container>
  
    </Main>
  );
};

export default CCA;
