import React from 'react';
import Grid from '@mui/material/Grid';
// import Avatar from '@mui/material/Avatar';
import { Link, Typography } from '@mui/material';



const Advantages = () => {
  return (
    
    <Typography align='center' variant='h4' fontWeight={800}>
    Co-Curricular Activities 2022-23</Typography>
  );
};

export default Advantages;
